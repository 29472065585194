<template>
  <!-- 签约管理tab页 -->
  <div class="contractFileReview">

    <!-- 2022/8/9 需求变更，统一页面，取消tab -->
    <content-main :activeNames="activeNames" :role="role" v-if="activeNames==='1'" ></content-main>

    <!-- 供应商和平台统一 -->
    <!-- <base-tabs type="border-card" v-model="activeNames"  @click="hander" >
      <base-tab-pane label="签约文件" name="1">
       <content-main :activeNames="activeNames" :role="role" v-if="activeNames==='1'" ></content-main>
       </base-tab-pane>
      <base-tab-pane label="补签文件" name="2">
      <sig-management-repair :activeNames="activeNames" :role="role" v-if="activeNames==='2'"></sig-management-repair>
      </base-tab-pane>
    </base-tabs> -->
  </div>
</template>
<script>
// import BaseTabs from '@/components/common/tabs/base-tabs/base-tabs.vue'
// import BaseTabPane from '@/components/common/tabs/base-tab-pane/base-tab-pane.vue'
import ContentMain from './sign-management-main.vue'
// import SigManagementRepair from './sign-management-repair.vue'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileReview',
  components: { ContentMain },
  // components: { ContentMain },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      activeNames: '1'
    }
  },
  created () {
    // 获取角色信息
    this.role = this.$store.state.user.userInfo.userType
  },
  mounted () {
    // 储存选择状态
    if (Storage.getSession('activeNames')) {
      this.activeNames = Storage.getSession('activeNames').toString()
    }
  },
  methods: {
    hander () {
      Storage.setSession('activeNames', this.activeNames)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs--border-card {
  border: 0;
  box-shadow: none;
}
/deep/ .el-tabs__content {
  padding: 0 !important;
}

.contractFileReview {
  height: 100%;
  margin-top: 10px;
  .tabs {
    margin-top: 10px;
  }
}
</style>
