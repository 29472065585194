//签约管理 签约文件一览
<template>
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    >
    </base-form>
    <div class="line"></div>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: false,
        rowClassName:tableRowClassName,
        highlightCurrentRow:false
      }"
      ref="tableData"
      :loadCount="loadCount"
      :dataSource.sync="tableData"
      :queryParas.sync="queryParas"
      :api="api"
      :getApi="role == '20'?'platformPage': 'supplierPage'"
      :webPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!--业务经理-->
        <!-- 查看   分补签和已签约 分电子章和鲜章  -->
        <icon-button
          @click="jumpSignFile(scope.row)"
          v-if="showInfo(scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        />
          <icon-button
          @click="sendSignCheck(scope.row)"
          content="发起电子章查验"
          icon="iconfont iconshenhe1"
          v-if="scope.row.status==='2'||scope.row.status==='3'"
        />
        <!-- 申请签约 -->
        <!--供应商-->
        <icon-button
          @click="jumpSignFile(scope.row)"
          v-if="role === '22' && scope.row.status === '0'"
          content="申请签约"
          icon="iconfont iconshenqing"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { signApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'

import Storage from '@/utils/storage'
import { getDictLists } from '@/filters/fromDict'

export default {
  props: {
    activeNames: String
  },
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      role: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        gysId: ''
      },
      tableData: [],
      loadCount: 0,
      gysId: ''

    }
  },
  created () {
    // 获取角色信息
    this.role = this.$store.state.user.userInfo.userType
    // 获取供应商id
    this.orgAnizationId = this.$store.state.user.userInfo.organizationId
  },
  computed: {
    supplierApi () {
      return supplierApi
    },
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return signApi
    },
    // 签约状态
    signStatusOptions () {
      return getDictLists('SIGN_TASK_STATUS')
    },
    // 盖章状态
    suppliersSealType () {
      return (getDictLists('SUPPLIERS_SEAL_TYPE')).filter(item => { // 过滤2.未确定类型
        if (item.dictId !== '2') {
          return item
        }
      })
    }

  },
  watch: {
    // 供应商id存在，增加gysid在调用分页查询表格
    // 'queryParas.gysId' (val) {
    //   if (val && this.role === '22') {
    //     this.queryParas.gysId = this.gysId
    //     this.handleFilter()
    //   }
    // }
  },

  mounted () {
    // 获取供应商id
    this.add()
  },
  methods: {
    // 发起电子章查验
    sendSignCheck (row) {
      this.$stateConfirm({
        title: '提示',
        message: '是否发起电子章查验？',
        show: true,
        type: 'warning'
      }).then(() => {
        const data = { taskId: row.keyId }
        signApi.sginExamine(data).then(res => {
          if (res.data) {
            this.success('发起成功')
          } else {
            this.warning('发起失败')
          }
        })
      })
    },

    tableRowClassName ({ row, rowIndex }) {
      if (row.validType === 2 || row.validType === 3) {
        return 'highlight'
      } else {
        return ''
      }
    },
    // 获取企业信息 查询增加gysid
    add () {
      if (this.orgAnizationId) {
        const params = {
          orgAnizationId: this.orgAnizationId
        }
        this.supplierApi.getEnterpriseInfo(params).then((res) => {
          this.$set(this.queryParas, 'gysId', res.data.keyId)
          this.gysId = res.data.keyId
          this.loadCount++
        })
      } else {
        this.loadCount++
      }
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        taskType: 0
      }
    },
    //  查询表格数据
    handleFilter () {
      if (this.gysId) {
        this.$set(this.queryParas, 'gysId', this.gysId)
      }
      this.$nextTick(() => {
        this.loadCount++
      })
    },

    // 查看按钮显示判断
    showInfo (row) {
      if (this.role !== '22') {
        // 角色不为供应商时都可查看
        return true
      } else {
        if (row.status === '1' || row.status === '2') {
          return true
        } else {
          return false
        }
      }
    },
    // 跳转到签约文件
    jumpSignFile (signData) {
      // sessionStorage.setItem('activeNames', this.activeNames)
      signData.applyAmount = signData.applyAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2
      })
      Storage.setSession('signData', signData)
      this.$router.push({
        path: '/business/signFileDetails',
        query: { role: this.role }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.moni {
  position: fixed;
  top: 20px;
  left: 500px;
}
.line {
  height: 10px;
  background: #f6f6f4;
}
/deep/ .highlight {
    .cell{
      color: #F90606;
    }
  }
</style>
