var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-form',{staticClass:"formStyle",attrs:{"componentList":_vm.formConfig,"formAttrs":{
      model: _vm.queryParas,
      labelWidth: '90px',
    }},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('div',{staticClass:"line"}),_c('base-table',{ref:"tableData",staticClass:"main-page-table",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
      data: _vm.tableData,
      stripe: false,
      rowClassName:_vm.tableRowClassName,
      highlightCurrentRow:false
    },"loadCount":_vm.loadCount,"dataSource":_vm.tableData,"queryParas":_vm.queryParas,"api":_vm.api,"getApi":_vm.role == '20'?'platformPage': 'supplierPage',"webPage":false},on:{"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event},"update:queryParas":function($event){_vm.queryParas=$event},"update:query-paras":function($event){_vm.queryParas=$event}},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"action",fn:function(scope){return [(_vm.showInfo(scope.row))?_c('icon-button',{attrs:{"content":"查看","icon":"iconfont iconchakan"},on:{"click":function($event){return _vm.jumpSignFile(scope.row)}}}):_vm._e(),(scope.row.status==='2'||scope.row.status==='3')?_c('icon-button',{attrs:{"content":"发起电子章查验","icon":"iconfont iconshenhe1"},on:{"click":function($event){return _vm.sendSignCheck(scope.row)}}}):_vm._e(),(_vm.role === '22' && scope.row.status === '0')?_c('icon-button',{attrs:{"content":"申请签约","icon":"iconfont iconshenqing"},on:{"click":function($event){return _vm.jumpSignFile(scope.row)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }